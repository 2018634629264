$(function() {
  $(document).on('click', function(e){
      if($(e.target).closest('.header-my-menu').length){
          $('.header-mymenu-content').toggleClass('is-active');
      } else if ($(e.target).closest('.header-mymenu-content').length){
          return;
      } else {
          if($('.header-mymenu-content').hasClass('is-active')){
              $('.header-mymenu-content').removeClass('is-active');
          }
      }
  });
  $('.content').on('click', function(e){
      if($(e.target).closest('.header-nav-icon').length){
          $('.header-nav-base').toggleClass('is-active');
      }else if ($(e.target).closest('.header-nav-base').length){
          return;
      } else {
          if($('.header-nav-base').hasClass('is-active')){
              $('.header-nav-base').removeClass('is-active');
          }
      }
  });
  $(window).on('resize',function(){
      if($(this).outerWidth() > 820) {
          if ($('.header-nav-base').hasClass('is-active')) {
              $('.header-nav-base').removeClass('is-active');
          }
      }
  });
  if($('.page-side-nav').length){
      $sideNavPosition = $('.page-side-nav').offset().top;
      $headerHeight = $('.header').outerHeight();
      $(window).on('scroll resize', function() {
          if ($(window).innerWidth() > 820) {
              if($(window).scrollTop() > $sideNavPosition - $headerHeight - 30) {
                  $('.page-side-nav').addClass('is-fixed');
              } else {
                  $('.page-side-nav').removeClass('is-fixed');
              }
          } else {
              if ($('.page-side-nav').hasClass('is-fixed')) {
                  $('.page-side-nav').removeClass('is-fixed');
              }
          }
      });
  }
});

//[Twitter Intent](https://dev.twitter.com/web/intents#tweet-intent)
(function () {
  if (window.__twitterIntentHandler) return;
  var intentRegex = /twitter\.com\/intent\/(\w+)/,
    windowOptions = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes',
    width = 550,
    height = 420,
    winHeight = screen.height,
    winWidth = screen.width;

  function handleIntent(e) {
    e = e || window.event;
    var target = e.target || e.srcElement,
      m = void 0,
      left = void 0,
      top = void 0;

    while (target && target.nodeName.toLowerCase() !== 'a') {
      target = target.parentNode;
    }

    if (target && target.nodeName.toLowerCase() === 'a' && target.href) {
      m = target.href.match(intentRegex);
      if (m) {
        left = Math.round(winWidth / 2 - width / 2);
        top = 0;

        if (winHeight > height) {
          top = Math.round(winHeight / 2 - height / 2);
        }

        window.open(target.href, 'intent', windowOptions + ',width=' + width + ',height=' + height + ',left=' + left + ',top=' + top);
        e.returnValue = false;
        e.preventDefault && e.preventDefault();
      }
    }
  }
  if (document.addEventListener) {
    document.addEventListener('click', handleIntent, false);
  } else if (document.attachEvent) {
    document.attachEvent('onclick', handleIntent);
  }
  window.__twitterIntentHandler = true;
})();
